exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-bgroovia-index-js": () => import("./../../../src/pages/bgroovia/index.js" /* webpackChunkName: "component---src-pages-bgroovia-index-js" */),
  "component---src-pages-c-collection-id-js": () => import("./../../../src/pages/c/[collection_id].js" /* webpackChunkName: "component---src-pages-c-collection-id-js" */),
  "component---src-pages-c-create-index-js": () => import("./../../../src/pages/c/create/index.js" /* webpackChunkName: "component---src-pages-c-create-index-js" */),
  "component---src-pages-c-edit-collection-id-js": () => import("./../../../src/pages/c/edit/[collection_id].js" /* webpackChunkName: "component---src-pages-c-edit-collection-id-js" */),
  "component---src-pages-creators-index-js": () => import("./../../../src/pages/creators/index.js" /* webpackChunkName: "component---src-pages-creators-index-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-feed-index-js": () => import("./../../../src/pages/feed/index.js" /* webpackChunkName: "component---src-pages-feed-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listings-index-js": () => import("./../../../src/pages/listings/index.js" /* webpackChunkName: "component---src-pages-listings-index-js" */),
  "component---src-pages-loading-index-js": () => import("./../../../src/pages/loading/index.js" /* webpackChunkName: "component---src-pages-loading-index-js" */),
  "component---src-pages-m-create-index-js": () => import("./../../../src/pages/m/create/index.js" /* webpackChunkName: "component---src-pages-m-create-index-js" */),
  "component---src-pages-m-edit-media-id-js": () => import("./../../../src/pages/m/edit/[media_id].js" /* webpackChunkName: "component---src-pages-m-edit-media-id-js" */),
  "component---src-pages-m-list-media-id-js": () => import("./../../../src/pages/m/list/[media_id].js" /* webpackChunkName: "component---src-pages-m-list-media-id-js" */),
  "component---src-pages-m-media-id-js": () => import("./../../../src/pages/m/[media_id].js" /* webpackChunkName: "component---src-pages-m-media-id-js" */),
  "component---src-pages-m-mint-media-id-js": () => import("./../../../src/pages/m/mint/[media_id].js" /* webpackChunkName: "component---src-pages-m-mint-media-id-js" */),
  "component---src-pages-m-mint-setup-media-id-js": () => import("./../../../src/pages/m/mint/setup/[media_id].js" /* webpackChunkName: "component---src-pages-m-mint-setup-media-id-js" */),
  "component---src-pages-metadata-chain-id-token-address-token-id-js": () => import("./../../../src/pages/metadata/[chain_id]/[token_address]/[token_id].js" /* webpackChunkName: "component---src-pages-metadata-chain-id-token-address-token-id-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-setup-index-js": () => import("./../../../src/pages/setup/index.js" /* webpackChunkName: "component---src-pages-setup-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-u-[displayname]-js": () => import("./../../../src/pages/u/[displayname].js" /* webpackChunkName: "component---src-pages-u-[displayname]-js" */),
  "component---src-pages-u-edit-[displayname]-js": () => import("./../../../src/pages/u/edit/[displayname].js" /* webpackChunkName: "component---src-pages-u-edit-[displayname]-js" */),
  "component---src-pages-u-observer-home-js": () => import("./../../../src/pages/u/ObserverHome.js" /* webpackChunkName: "component---src-pages-u-observer-home-js" */)
}

